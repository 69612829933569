<template lang="pug">
    div.gallery-viewer
        v-toolbar.header(dark fixed app height="56")  
            v-toolbar-title
                img.mt-2(height="70" src="/public/img/logo.png" alt="Wappid") 
        v-content
            v-container.grid-list-xl(fluid)
                h3(v-if="preload") {{ $t('Cargando...') }}
                template(v-else)
                    template(v-if="Object.keys(gallery).length")
                        h3.mb-2 {{ gallery.title }}
                        figure
                            img(:src="imgurl + gallery.image")
                        p.mt-2 {{ gallery.description }}
                    h3(v-else) {{ $t('No se ha encontrado ninguna imagen') }}
</template>

<script>

    import config from 'src/config'
    export default {
        metaInfo() {
            return {
                title: this.$t(this.title)
            }
        },
        data() {
            return {
                preload: false,
                gallery: {}
            }
        },
        computed: {
            title(){
                return this.gallery.title || this.$t('Imagen de la galería')
            },
            imgurl(){
                return config.uploadimg
            },
            uuid(){
                return this.$route.params.id
            }
        },
        created(){
            this.fetchGallery()
        },
        methods: {

            fetchGallery(){

                this.preload = true
                this.$api(this, (xhr) => {
                    xhr.get('/gallery/' + this.uuid).then((r) => {

                        this.preload = false
                        let data = r.data

                        this.gallery = (data.response ? data.data : [])
                    })
                }).catch(() => {})
            }
        }
    }
    
</script>